import baseURL from "@/utils/config";
import request from "@/plugins/axios"

//活动列表
export function getSearchSeeDetails(data,langEnv=2){
  return request({
      url:`${baseURL.javaApi}/api/specialEvent/seeDetails`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}
