<template>
  <van-popup
    v-model:show="show"
    position="bottom"
    :style="{ height: '60%', borderRadius: '16px 16px 0 0' }"
    @closed="handleClose"
  >
    <div class="title">
      <div></div>
      <div>選擇所在地區</div>
      <div class="close">
        <img
          @click="show = false"
          style="width: 100%"
          src="@/assets/icons/ic_Close@2x.png"
          alt=""
        />
      </div>
    </div>
    <div class="address-list">
      <div class="address-tab">
        <div>{{ adderssSelect.label }}</div>
        <div
          class="alter"
          v-if="adderssSelect.label !== '請選擇'"
          @click="handlEditArea(item, '1')"
        >
          更改
        </div>
      </div>
      <div v-if="adderssSelect.isFlag">
        <div
          class="address-item"
          v-for="item in adderssSelect.child"
          :key="item.code"
          @click="handleAreaCode(item, '1')"
        >
          {{ item.zh }}
        </div>
      </div>
    </div>
    <div class="address-list">
      <div class="address-tab">
        <div>{{ adderssSelect1.label }}</div>
        <div
          class="alter"
          v-if="adderssSelect.label !== '請選擇'"
          @click="handlEditArea(item, '2')"
        >
          更改
        </div>
      </div>
      <div v-if="adderssSelect1.isFlag">
        <div
          class="address-item"
          v-for="item in adderssSelect1.child"
          :key="item.code"
          @click="handleAreaCode(item, '2')"
        >
          {{ item.zh }}
        </div>
      </div>
    </div>
    <div class="address-list">
      <div class="address-tab">
        <div>{{ adderssSelect2.label }}</div>
        <div
          class="alter"
          v-if="adderssSelect.label !== '請選擇'"
          @click="handlEditArea(item, '3')"
        >
          更改
        </div>
      </div>
      <div v-if="adderssSelect2.isFlag">
        <div
          class="address-item"
          v-for="item in adderssSelect2.child"
          :key="item.code"
          @click="handleAreaCode(item, '3')"
        >
          {{ item.zh }}
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getAddress } from "@/api//user.js";
export default {
  name: "addressCascadera",
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    echoList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      AddressList: [],
      adderssSelect: { label: "請選擇", value: "1", child: [], isFlag: true },
      adderssSelect1: { label: "", value: "2", child: [], isFlag: false },
      adderssSelect2: { label: "", value: "3", child: [], isFlag: false },
      changAdderss: "",
      changAdderssechoList: [],
      resultList: []
    };
  },
  watch: {
    async isShow() {
      this.show = this.isShow;
      this.changAdderssechoList = this.echoList;
      await this.getAddressList();
    },
    echoList(val) {
      console.log(val);
    }
  },
  methods: {
    collectData(item, level) {
      if (level === 1) {
        this.adderssSelect.child.push(item);
      } else if (level === 2) {
        this.adderssSelect1.child.push(item);
      } else if (level === 3) {
        this.adderssSelect2.child.push(item);
      }

      if (item.cities) {
        item.cities.forEach(city => this.collectData(city, level + 1));
      } else if (item.districts) {
        item.districts.forEach(district =>
          this.collectData(district, level + 1)
        );
      }
    },
    replaceFields(item) {
      if (item.districts) {
        item.child = item.districts;
        delete item.districts;
      }

      if (item.cities) {
        item.child = item.cities;
        delete item.cities;
      }

      if (item.child) {
        item.child.forEach(child => this.replaceFields(child));
      }
    },
    findElementByCode(data, code) {
      for (let item of data) {
        if (item.code === code) {
          return item;
        } else if (item.child && item.child.length > 0) {
          const foundInCities = this.findElementByCode(item.child, code);
          if (foundInCities) {
            return foundInCities;
          }
        }
      }
      return null;
    },
    handlEditArea(item, index) {
      console.log(this.adderssSelect);
      if (index == 1) {
        this.adderssSelect.label = "請選擇";
        this.adderssSelect.isFlag = true;
        this.adderssSelect1.isFlag = false;
      } else if (index == "2") {
        this.adderssSelect1.label = "請選擇";
        this.adderssSelect2.isFlag = false;
        this.adderssSelect1.isFlag = true;
      } else {
        this.adderssSelect2.label = "請選擇";
        this.adderssSelect2.isFlag = true;
      }

      // console.log(this.adderssSelect);

      // this.AddressList = this.adderssSelect[index - 1].child;

      // if (index === 0) {
      //   this.getReset();
      // }
      // const addressIndex = this.adderssSelect.findIndex(
      //   items => items.value == item.value
      // );
      // this.adderssSelect.splice(addressIndex, 1, {
      //   label: "請選擇",
      //   value: "1"
      // });
      // this.adderssSelect.splice(addressIndex, 1);
    },
    async handleAreaCode(item, index) {
      console.log(item);
      if (index == "1") {
        console.log(item);
        this.adderssSelect.label = item.zh;
        this.adderssSelect1.isFlag = true;
        this.adderssSelect.isFlag = false;
        this.adderssSelect1.label = "請選擇";
      } else if (index == "2") {
        this.adderssSelect1.label = item.zh;
        this.adderssSelect1.isFlag = false;
        this.adderssSelect2.isFlag = true;
        this.adderssSelect2.label = "請選擇";
      } else {
        this.adderssSelect2.label = item.zh;
        this.adderssSelect2.isFlag = false;
      }
      if (this.adderssSelect2.label) {
        this.resultList.push({ value: item.code, label: item.zh });
        this.$emit("handleAreaCode", this.resultList);
        console.log(this.resultList);
        this.handleClose();
      }
      // this.adderssSelect.push({ label: "請選擇", value: "1" });
      // const index = this.adderssSelect.findIndex(item => item.value == "1");
      // this.adderssSelect.splice(index, 1, {
      //   label: item.zh,
      //   value: item.code,
      //   child: item.child
      // });

      // this.resultList.push(item);
      // if (item.cities?.length > 0) {
      //   this.adderssSelect1.child = item.cities;
      //   // this.AddressList = item.child;
      //   // this.changAdderss = item;
      // } else {

      // }
    },
    async getAddressList() {
      const res = await getAddress();
      this.AddressList = res.data;
      // this.AddressList.forEach(item => this.replaceFields(item));
      (this.adderssSelect = {
        label: "請選擇",
        value: "1",
        child: [],
        isFlag: true
      }),
        (this.adderssSelect1 = {
          label: "",
          value: "2",
          child: [],
          isFlag: false
        }),
        (this.adderssSelect2 = {
          label: "",
          value: "3",
          child: [],
          isFlag: false
        }),
        this.AddressList?.forEach(item => this.collectData(item, 1));
      console.log(this.adderssSelect);

      // console.log(this.AddressList);
      // if (this.changAdderssechoList.length > 0) {
      //   const res1 = this.changAdderssechoList.map(item =>
      //     this.findElementByCode(this.AddressList, item)
      //   );
      //   this.adderssSelect = res1.map(item => {
      //     return { label: item.zh, value: item.code, child: item.child };
      //   });
      //   console.log(res1);
      // }
    },
    async getReset() {
      // await this.getAddressList();
      this.adderssSelect = [{ label: "請選擇", value: "1" }];
    },
    async handleClose() {
      this.$emit("handleClose");
      this.getReset();
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;

  .close {
    width: 20px;
  }
}
.address-list {
  padding: 0 16px;
  .address-tab {
    font-size: 16px;
    color: #1a1a1a;
    line-height: 24px;
    padding: 14px 8px;
    display: flex;
    justify-content: space-between;
    .alter {
      font-size: 17px;
      color: #90d12e;
      line-height: 24px;
    }
  }
  .address-item {
    font-size: 16px;
    color: #666666;
    line-height: 24px;
    padding: 14px 8px;
  }
}
</style>
