export default [
  {
    path: '/order/confirm2',
    name: 'OrderConfirm2',
    component: () => import('@/views/order/index.vue'),

  },
  {
    path: '/user/orderList',
    name: 'OrderList',
    component: () => import('@/views/order/orderList.vue'),

  },

]