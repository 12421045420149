import {
  getUser,
  getAddressList,
  getEditDistributionAddress,
  getPhoneAreaCode
} from "@/api/user";

const state = {
  userInfo: {},
  address: [],
  checkId: "",
  checkAddress: {},
  phoneCode: [],
  registerForm: {},
  cacheFileList: []
};

// getters
const getters = {};

// actions
const actions = {
  getFileList(context, data){
    context.commit("setFileList", data);

  },
  getregisterForm(context, data) {
    context.commit("setRegisterForm", data);
  },
  //获取用户信息
  async getUserInfo(context, data) {
    const res = await getUser({ type: 2 });
    if (res.resultID == 1200) {
      context.commit("setUserInfo", res.data);
    }
  },
  //获取地址列表
  async getAddressList(context, data) {
    const res = await getAddressList();
    if (res.resultID == 1200) {
      context.commit("setAddress", res.data);

      if (data) {
        context.commit("setAddressId", data);
      }
    }
  },
  // 修改选中地址
  async getEditAddress(context, data) {
    context.commit("setAddressId", data);
    await getEditDistributionAddress({ id: data });
  },
  //获取地区手机编码
  async getPhoneCode(context, data) {
    const res = await getPhoneAreaCode();
    if (res.resultID == 1200) {
      context.commit("setPhoneCode", res.data);
    }
  }
};

// mutations
const mutations = {
  setFileList(state, data) {
    state.cacheFileList = data;
  },
  setRegisterForm(state, data) {
    state.registerForm = data;
    console.log(state.registerForm);
  },
  setAddressId(state, data) {
    state.checkId = data;
    state.checkAddress = state.address.find(item => item.id == data);
  },
  setUserInfo(state, data) {
    state.userInfo = data;
  },
  setAddress(state, data) {
    state.address = data;
  },
  setPhoneCode(state, data) {
    state.phoneCode = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
