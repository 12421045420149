<template>
  <div>
    <van-popup
      v-model:show="show"
      position="bottom"
      :close-on-click-overlay="false"
      :style="{ height: '40%', borderRadius: '16px 16px 0 0' }"
    >
      <div class="titleArea">
        <div></div>
        <div>{{ $t("國家地區") }}</div>
        <div class="close">
          <img
            @click="show = false"
            style="width: 100%"
            src="@/assets/icons/ic_Close@2x.png"
            alt=""
          />
        </div>
      </div>
      <div class="phone-list">
        <div
          :class="['phone-item', { actvie: item.value == selectPhone }]"
          v-for="item in columns"
          :key="item.value"
          @click="handleChange(item)"
        >
          <div class="phone-text">{{ item.label }}</div>
          <img
            class="phone-check"
            v-if="item.value == selectPhone"
            src="@/assets/icons/check.png"
            alt=""
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "selectDialog",
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    defaultValue:{
      type: String,
      default:'2'
    }
  },
  data() {
    return {
      show: false,
      selectPhone: "2",
      columns: []
    };
  },
  watch: {
    isShow(val) {
      this.show = this.isShow;
      this.columns = this.data;
      this.selectPhone=this.defaultValue
    }
  },
  methods: {
    handleChange(item) {
      this.$emit("handleChange", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.phone-list {
  padding: 20px 16px;
  .actvie {
    border: 2px solid #90d12e !important;
  }
  .phone-item {
    padding: 20px;
    position: relative;
    background: #f8f8f8;
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 12px;
    border: 2px solid transparent;
    .phone-text {
      font-size: 16px;
      color: #1a1a1a;
      line-height: 16px;
      text-align: center;
    }
    .phone-check {
      position: absolute;
      width: 24px;
      bottom: -1px;
      right: -1px;
    }
  }
}
.titleArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;

  .close {
    width: 20px;
  }
}
</style>
