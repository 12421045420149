<template>
  <van-popup
    v-model:show="show"
    position="bottom"
    :style="{ height: '60%', borderRadius: '16px 16px 0 0' }"
    @closed="handleClose"
  >
    <div class="title">
      <div></div>
      <div>{{ $t("選擇所在地區") }}</div>
      <div class="close">
        <img
          @click="show = false"
          style="width: 100%"
          src="@/assets/icons/ic_Close@2x.png"
          alt=""
        />
      </div>
    </div>
    <div class="address-list">
      <div
        class="address-tab"
        v-for="(item, index) in adderssSelect"
        :key="index"
      >
        <div>{{ item.label }}</div>
        <div
          class="alter"
          v-if="item.value !== '1'"
          @click="handlEditArea(item, index)"
        >
        {{ $t("更改") }} 
        </div>
      </div>
      <div
        class="address-item"
        v-for="item in AddressList"
        :key="item.code"
        @click="handleAreaCode(item)"
      >
        {{ item.zh }}
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getAddress } from "@/api//user.js";
export default {
  name: "addressCascader",
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      AddressList: [],
      adderssSelect: [{ label: this.$t('請選擇') , value: "1" }],
      changAdderss: ""
    };
  },
  watch: {
    isShow() {
      this.show = this.isShow;
      this.getAddressList();
    }
  },
  methods: {
    handlEditArea(item, index) {
      this.AddressList = this.changAdderss.cities;
      if (index === 0) {
        this.getReset();
      }
      const addressIndex = this.adderssSelect.findIndex(
        items => items.value == item.value
      );
      this.adderssSelect.splice(addressIndex, 1);
    },
    async handleAreaCode(item) {
      this.adderssSelect.push({ label: this.$t('請選擇'), value: "1" });
      const index = this.adderssSelect.findIndex(item => item.value == "1");
      this.adderssSelect.splice(index, 1, { label: item.zh, value: item.code });
      console.log(item);

      if (item.cities?.length > 0) {
        this.AddressList = item.cities;
        this.changAdderss = item;
      } else if (item.districts?.length > 0) {
        this.AddressList = item.districts;
      } else {
        this.$emit("handleAreaCode", this.adderssSelect);
        console.log(this.adderssSelect);
        this.handleClose();
      }
    },
    async getAddressList() {
      const res = await getAddress();
      this.AddressList = res.data;
    },
    async getReset() {
      this.adderssSelect = [{ label: this.$t('請選擇'), value: "1" }];
      await this.getAddressList();
    },
    async handleClose() {
      this.$emit("handleClose");
      this.getReset();
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;

  .close {
    width: 20px;
  }
}
.address-list {
  padding: 0 16px;
  .address-tab {
    font-size: 16px;
    color: #1a1a1a;
    line-height: 24px;
    padding: 14px 8px;
    display: flex;
    justify-content: space-between;
    .alter {
      font-size: 17px;
      color: #90d12e;
      line-height: 24px;
    }
  }
  .address-item {
    font-size: 16px;
    color: #666666;
    line-height: 24px;
    padding: 14px 8px;
  }
}
</style>
