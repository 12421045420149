export default [
  {
    path: '/newProduct/:tagID?',
    name: 'Newproduct',
    meta: {hideTab:true },
    component: () => import('@/views/newProduct/index.vue'),

  },
  {
    path: '/brandId/:brandId?',
    name: 'BrandProduct',
    meta: {hideTab:true },
    component: () => import('@/views/newProduct/index.vue'),

  },
  {
    path: '/categoryId/:categoryId?',
    name: 'CategoryProduct',
    meta: {hideTab:true },
    component: () => import('@/views/newProduct/index.vue'),

  },

]