<template>
  <form id="payment-form">
    <div class="title">
      <div>
        <img
          @click="handleClosestripe"
          src="@/assets/icons/Arrow_left.png"
          alt=""
        />
      </div>
    </div>
    <div id="card-element"></div>
    <div @click="handleSubmit" class="pay-btn">
      <div class="pay-btn-text">{{ $t("支付") }} {{ formatNumber(price) }}</div>
    </div>
  </form>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { getStripeKey } from "@/api/user.js";
import mixins from "@/utils/mixins.js";
export default {
  name: "stripe",
  mixins: [mixins],
  data() {
    return {
      stripe: null,
      elements: null
    };
  },
  props: {
    clientSecretKey: {
      type: String,
      default: ""
    },
    price: {
      type: Number,
      default: 0
    },
    orderNo: {
      type: String,
      default: ""
    }
  },
  created() {
    this.initStripe();
  },
  methods: {
    handleClosestripe() {
      this.$emit("handleClosestripe");
    },
    async handleSubmit() {
      this.$toast({
        message: this.$t("支付中"),
        duration: 0
      });
      const result = await this.stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements: this.elements,
        confirmParams: {
          return_url:
            window.location.origin +
            `/payResult?type=success&pay=1&payType=52&orderId=${this.orderNo}&price=${this.price}`
        }
      });
      this.$emit("handleSubmit", result);
    },
    async initStripe() {
      const res = await getStripeKey();
      const appearance = {
        theme: "stripe"
      };
      const options={
        defaultValues:{
          billingDetails:{
            address:{
              country:'HK'
            }
          }
        }
      }
      // 初始化stripe
      const stripe = await loadStripe(res.data);
      this.stripe = stripe;
      // Create an instance of Elements.
      this.elements = this.stripe.elements({
        locale: "en",
        appearance,
        clientSecret: this.clientSecretKey
        // 设置默认显示语种
      });
      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      const style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": { color: "#aab7c4" }
        },
        invalid: { color: "#fa755a", iconColor: "#fa755a" }
      };
      // Create an instance of the card Element.
      const card = this.elements.create("payment",options);
      // Add an instance of the card Element into the `card-element` <div>.
      card.mount("#card-element");
      // Handle real-time validation errors from the card Element.
      card.addEventListener("change", function(event) {


   

        // if (event.error) {
        //   console.log("event.error", event.error);
        //   displayError.textContent = event.error.message;
        // } else {
        //   displayError.textContent = "";
        // }
      });
      // Handle form submission.
    }
  }
};
</script>

<style lang="scss" scoped>
#payment-form {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      width: 24px;
    }
  }

  .pay-btn {
    width: 100%;

    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    .pay-btn-text {
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      color: #fff;
      background: #90d12e;
    }
  }
}
</style>
