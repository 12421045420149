export default [
  {
    path: "/question",
    name: "Question",
    meta: { hideTab: true },
    component: () => import("@/views/question/index.vue")
  },
  {
    path: "/questionDetail/:id/:title",
    name: "QuestionDetail",
    meta: { hideTab: true },
    component: () => import("@/views/question/detailList.vue")
  }
];
