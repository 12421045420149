
function getNetApiHost(values) {
	let url = "";
	const type = {
		development: 'http://192.168.80.231:7003', // 开发地址
		production: 'https://api.e-rith.com',
		test: 'http://192.168.80.231:7003' // 测试地址
	};
	url = type[values];
	return url;
}
function getNetJavHost(values) {
	let url = "";
	const type = {
		development: 'http://192.168.80.231:7005', // 开发地址
		production: 'https://service.e-rith.com',
		test: 'http://192.168.80.231:7005' // 测试地址
	};
	url = type[values];
	return url;
}
function getImageUrl(values) {
	let url = "";
	const type = {
		development: 'http://192.168.80.231:7007/upload', // 开发地址
		production: 'https://image.e-rith.com/upload',
		test: 'http://192.168.80.231:7007/upload' // 测试地址
	};
	url = type[values];
	return url;
}
export default  {
	api: getNetApiHost(process.env.NODE_ENV),
	imgUrl: getImageUrl(process.env.NODE_ENV),
	javaApi :getNetJavHost(process.env.NODE_ENV),
};
