import Active from '@/views/active/index.vue'
export default [
  {
    path: '/active11/:id',
    name: 'Active',
    meta: {
      hideTab:true
    },
    component: Active
  }
]