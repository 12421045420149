export default [
    {
		path: '/reviewRecord',
		name: 'reviewRecord',
		component: ()=>import('@/views/priceChangeReview/reviewRecord.vue'),
		meta: { title: "",hideTab:true },
	},
    {
        path: '/priceReview/:id',
		name: 'priceReview',
		component: ()=>import('@/views/priceChangeReview/index.vue'),
		meta: { title: "",hideTab:true },
	},
    
  ]