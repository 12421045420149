export default [
  {
    path: '/balance',
    name: 'Balance',
    meta: {
      hideTab:true
    },
    component: () => import('@/views/balance/index.vue')
  },
  {
    path: '/balanceDetial',
    name: 'BalanceDetial',
    meta: {
      hideTab:true
    },
    component: () => import('@/views/balance/detial.vue')
  }
]