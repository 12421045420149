<template>
  <div>
    <div
      @click.stop.prevent="getToUser"
      class="suspension"
      @touchstart="onTouchStart"
      @touchmove.prevent="onTouchMove"
      @touchend="onTouchEnd"
      :style="{ transform: buttonTransform }"
      ref="buttonRef"
    >
      <img src="@/assets/image/user/user.png" alt="" />{{ $t("我的") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "draggableTab",
  data() {
    return {
      whiteList: ["/reviewRecord"],
      isAndroid: null,
      isiOS: null,
      isDragging: false,
      touchStartX: 0,
      touchStartY: 0,
      buttonPositionX: 0,
      buttonPositionY: 0,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      buttonWidth: 100,
      buttonHeight: 50
    };
  },
  computed: {
    buttonTransform() {
      return `translate(${this.buttonPositionX}px, ${this.buttonPositionY}px)`;
    }
  },
  methods: {
    onTouchStart(e) {

      this.touchStartX = e.touches[0].clientX;
      this.touchStartY = e.touches[0].clientY;
    },
    onTouchMove(e) {
      this.isDragging = true;
      if (!this.isDragging) return;

      const newX =
        this.buttonPositionX + (e.touches[0].clientX - this.touchStartX);
      const newY =
        this.buttonPositionY + (e.touches[0].clientY - this.touchStartY);

      // 更新按钮的位置
      this.buttonPositionX = newX;

      this.buttonPositionY = newY;

      // 更新起始触摸位置
      this.touchStartX = e.touches[0].clientX;
      this.touchStartY = e.touches[0].clientY;
    },
    onTouchEnd(e) {
      if (!this.isDragging) {
        this.getToUser();
      }
      if (
        this.buttonPositionX < 0 &&
        this.buttonPositionX <= -this.windowWidth / 2
      ) {
        this.buttonPositionX =
          this.buttonPositionX <= -this.windowWidth + e.target.offsetWidth + 20
            ? -this.windowWidth + e.target.offsetWidth + 20
            : this.buttonPositionX;
      } else {
        this.buttonPositionX = 0;
      }
      if (this.buttonPositionY < 0) {
        this.buttonPositionY =
          this.buttonPositionY <= -this.windowHeight / 2 + e.target.offsetHeight
            ? -this.windowHeight / 2 + e.target.offsetHeight
            : this.buttonPositionY;
      } else if (this.buttonPositionY >= this.windowHeight / 2) {
        this.buttonPositionY =
          this.windowHeight / 2 - (e.target.offsetHeight + 20);
      }
      this.isDragging = false;
    },
    onSelect(val) {},
    getToUser() {
      this.$router.push("/user/information");
    }
  }
};
</script>

<style lang="scss">
.suspension {
  position: fixed !important;
  top: 50%;
  right: 10px;
  color: #999999;
  background: #f8f8f8 !important;
  line-height: 1;
  font-size: 12px;
  padding: 10px 12px;
  z-index: 10000;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: none !important;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    margin-right: 4px;
  }
}
</style>
